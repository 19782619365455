/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import Autocomplete from '../../../utils/Autocomplete';
import AxiosAuth from '../../../utils/AxiosAuth';

function ModalCpanelVendor({ show, handleClose }) {
    const [inputValueSl, setInputValueSl] = useState('');
    const [host, setHost] = useState('');
    const [ipPrimary, setIpPrimary] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [location, setLocation] = useState('');
    const [serverType, setServerType] = useState('vps');

    const handleInput = (e) => {
        if (e.target.name === 'host') {
            setHost(e.target.value);
        }
        if (e.target.name === 'ipPrimary') {
            setIpPrimary(e.target.value);
        }
        if (e.target.name === 'apiKey') {
            setApiKey(e.target.value);
        }
        if (e.target.name === 'location') {
            setLocation(e.target.value);
        }
        if (e.target.name === 'serverType') {
            setServerType(e.target.value);
        }
    };
    const handleContractDomain = () => {
        AxiosAuth.post(ConfigApi.CPANEL_VENDOR_CREATE, {
            userSl: inputValueSl,
            host,
            ipPrimary,
            apiKey,
            location,
            serverType,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create New Cpanel Vendor:</div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Select User:</label>
                    <div className="contract-create-input-custom">
                        <Autocomplete setInputValueSl={setInputValueSl} />
                    </div>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Host:</label>
                    <input
                        type="text"
                        name="host"
                        value={host}
                        placeholder="Type Host"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">IP Primary:</label>

                    <input
                        type="text"
                        name="ipPrimary"
                        value={ipPrimary}
                        placeholder="Type IP Primary"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">API Key:</label>
                    <input
                        type="text"
                        name="apiKey"
                        value={apiKey}
                        placeholder="Type API Key"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Location:</label>
                    <input
                        type="text"
                        name="location"
                        value={location}
                        placeholder="Type Location"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="schedule">Server Type:</label>
                    <select
                        className="form-control"
                        name="serverType"
                        value={serverType}
                        onChange={handleInput}
                    >
                        <option value="vps">Vps</option>
                        <option value="cloud">Cloud</option>
                        <option value="dedicated">Dedicated</option>
                        <option value="reseller">Reseller</option>
                    </select>
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleContractDomain}
                    >
                        Add
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCpanelVendor;
