import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import ModalCpanelVendor from '../modals/ModalCpanelVendor';
import ModalDomainAssignUser from '../modals/ModalDomainAssignUser';

function ManagesCpanelVendorPage() {
    const [vendorAr, setVendorAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CPANEL_VENDOR_LIST}`).then((response) => {
            setVendorAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel-header">
                <form method="get" action="" className="main-panel-header-search">
                    <div className="sidebar-panel-search">
                        <input
                            type="text"
                            name="content"
                            id="content"
                            placeholder="Search Cpanel Vendor"
                            aria-label=""
                        />
                        <img src={Searchicon} alt="Search" />
                    </div>

                    <button type="submit">Search</button>
                </form>
                <button type="button" onClick={() => setShowNew(true)}>
                    Add New Cpanel Vendor
                </button>
            </div>
            <div className="main-panel-body">
                <table className="main-panel-body-table mb-3">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>User</th>
                            <th>Host</th>
                            <th>IP Primary</th>
                            <th>API Key</th>
                            <th>Location</th>
                            <th>Server Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vendorAr?.map((row) => (
                            <tr key={row?.sl}>
                                <td>H{row?.sl}</td>
                                <td>{row?.username}</td>
                                <td>{row?.host}</td>
                                <td>{row?.ip_primary}</td>
                                <td>{row?.api_key}</td>
                                <td>{row?.location}</td>
                                <td>{row?.server_type}</td>
                                <td>{row?.status}</td>
                                <td />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ModalDomainAssignUser
                show={show}
                handleClose={handleClose}
                selectId={selectId}
                setSelectId={setSelectId}
            />
            <ModalCpanelVendor show={showNew} handleClose={handleClose} />
        </>
    );
}

export default ManagesCpanelVendorPage;
