import React from 'react';
import CalendarIcon from '../../../../assets/images/Calendar2.svg';
import SearchIcon from '../../../../assets/images/input-search-icon.svg';

function ManagesCartPage() {
    return (
        <>
            <div className="main-panel-header">
                <form method="get" action="" className="main-panel-header-search">
                    <div className="sidebar-panel-search">
                        <input
                            type="text"
                            name="content"
                            id="content"
                            placeholder="Search Cart"
                            aria-label=""
                            value=""
                        />
                        <img src={SearchIcon} alt="Search" />
                    </div>
                    <div className="sidebar-panel-search-date">
                        <input
                            type="text"
                            className="form-control flatpickr-input"
                            name="date"
                            id="date"
                            aria-label=""
                            autoComplete="off"
                            required=""
                            value="2022-11-16 to 2022-11-26"
                            placeholder="Select Date"
                            readOnly="readonly"
                        />
                        <img src={CalendarIcon} alt="Calendar Icon" />
                    </div>
                    <button type="submit">Search</button>
                </form>
            </div>
            <div className="main-panel-body">
                <table className="main-panel-body-table mb-3">
                    <thead className="main-panel-body-table-thead">
                        <tr className="main-panel-head-table-tr">
                            <th className="main-panel-body-table-th">ID</th>
                            <th className="main-panel-body-table-th">Name</th>
                            <th className="main-panel-body-table-th">Types</th>
                            <th className="main-panel-body-table-th">Title</th>
                            <th className="main-panel-body-table-th text-center">Duration</th>
                            <th className="main-panel-body-table-th">Subscribers</th>
                            <th className="main-panel-body-table-th">Sends</th>
                            <th className="main-panel-body-table-th text-center">Executed on</th>
                            <th className="main-panel-body-table-th">Status</th>
                            <th className="main-panel-body-table-th">Action</th>
                        </tr>
                    </thead>
                    <tbody />
                </table>
            </div>
        </>
    );
}

export default ManagesCartPage;
