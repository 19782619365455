import React from 'react';
import { useLocation } from 'react-router-dom';

const titleData = {
    '/manage/dashboard/': 'Dashboard',
    '/manage/cart/': 'Cart',
    '/manage/contract/support/': 'Support',
    '/manage/contract/domain/': 'Domain',
    '/manage/contract/hosting/': 'Hosting',
    '/manage/contract/server/': 'Server',
    '/manage/invoice/': 'Invoice',
    '/manage/finance/': 'Finance',
    '/manage/finance-currencies/': 'Finance Currencies',
    '/manage/finance-gateway/': 'Finance Gateway',
    '/manage/user/': 'User',
    '/manage/logs/': 'Logs',
    '/manage/message/': 'Message',
    '/manage/Reports/': 'Reports',
    '/manage/software-subscription/': 'Software Subscription',
};
function HeaderSection() {
    const { pathname } = useLocation();

    return (
        <div className="main-stricture-header">
            <button type="button" id="sideMenu" />
            <p className="main-stricture-header-p">{titleData[pathname]}</p>
        </div>
    );
}

export default HeaderSection;
