import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import ModalDomainAssignUser from '../modals/ModalDomainAssignUser';
import ModalDomainContract from '../modals/ModalDomainContract';

function ManagesDomainPage() {
    const [domainAr, setDomainAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    const handleClick = (e) => {
        setSelectId(e);
        setShow(true);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_DOMAIN_LIST}`).then((response) => {
            setDomainAr(response.data.dataAr);
        });
    }, [show, showNew]);

    return (
        <>
            <div className="main-panel-header">
                <form method="get" action="" className="main-panel-header-search">
                    <div className="sidebar-panel-search">
                        <input
                            type="text"
                            name="content"
                            id="content"
                            placeholder="Search Domain"
                            aria-label=""
                        />
                        <img src={Searchicon} alt="Search" />
                    </div>

                    <button type="submit">Search</button>
                </form>
                <button type="button" onClick={() => setShowNew(true)}>
                    Add New Contact
                </button>
            </div>
            <div className="main-panel-body">
                <table className="main-panel-body-table mb-3">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>User</th>
                            <th>Vendor</th>
                            <th>Date Created</th>
                            <th>Expired</th>
                            <th>Domain</th>
                            <th>Price</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {domainAr?.map((row) => (
                            <tr key={row?.sl}>
                                <td>D{row?.sl}</td>
                                <td>
                                    {row?.user_sl > 0 ? (
                                        row?.user_name
                                    ) : (
                                        <button
                                            type="button"
                                            style={{ background: 'unset', border: 'unset' }}
                                            onClick={() => handleClick(row?.sl)}
                                        >
                                            Not Set
                                        </button>
                                    )}
                                </td>
                                <td>{row?.vendor}</td>
                                <td>{row?.time_issue}</td>
                                <td>{row?.time_expire}</td>
                                <td>{row?.domain_name}</td>
                                <td>${row?.price?.toFixed(2)}</td>
                                <td>{row?.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ModalDomainAssignUser
                show={show}
                handleClose={handleClose}
                selectId={selectId}
                setSelectId={setSelectId}
            />
            <ModalDomainContract show={showNew} handleClose={handleClose} />
        </>
    );
}

export default ManagesDomainPage;
