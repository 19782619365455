import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import ModalUser from '../modals/ModalUser';

function ManagesUserPage() {
    const [userAr, setUserAr] = useState([]);
    const [showNew, setShowNew] = useState(false);

    const handleClose = () => {
        setShowNew(false);
    };

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.USER_LIST}`).then((response) => {
            setUserAr(response.data.dataAr);
        });
    }, [showNew]);
    return (
        <>
            <div className="main-panel-header">
                <form method="get" action="" className="main-panel-header-search">
                    <div className="sidebar-panel-search">
                        <input
                            type="text"
                            name="content"
                            id="content"
                            placeholder="Search User"
                            aria-label=""
                        />
                        <img src={Searchicon} alt="Search" />
                    </div>

                    <button type="submit">Search</button>
                </form>
                <button type="button" onClick={() => setShowNew(true)}>
                    Add New Contact
                </button>
            </div>
            <div className="main-panel-body">
                <table className="main-panel-body-table mb-3">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th width="15%">Provider</th>
                            <th>Join date</th>
                            <th width="15%">Address</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userAr?.map((row) => {
                            const addressAr = row?.addressAr?.map((adrs) => (
                                <li key={adrs?.sl}>
                                    {adrs?.line1}
                                    {adrs?.line2 ? `, ${adrs?.line2} ` : null}
                                    {adrs?.line3 ? `, ${adrs?.line3} ` : null}
                                    {adrs?.state ? `, ${adrs?.state} ` : null}
                                    {adrs?.city ? `, ${adrs?.city} ` : null}
                                    {adrs?.country ? `, ${adrs?.country} ` : null}
                                    {adrs?.zip ? `- ${adrs?.zip} ` : null}
                                </li>
                            ));
                            const providerAr = row?.providerAr?.map((pros) => (
                                <li key={pros?.sl}>
                                    {pros?.email}
                                    {pros?.local_provider ? `, ${pros?.local_provider} ` : null}
                                    {pros?.provider ? `, ${pros?.provider} ` : null}
                                </li>
                            ));
                            return (
                                <tr key={row?.sl}>
                                    <td>{row?.sl}</td>
                                    <td>{row?.name}</td>
                                    <td>{row?.username}</td>
                                    <td>
                                        <ul>{providerAr}</ul>
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{row?.join_date}</td>
                                    <td>
                                        <ul>{addressAr}</ul>
                                    </td>
                                    <td>{row?.status}</td>
                                    <td />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <ModalUser show={showNew} handleClose={handleClose} />
        </>
    );
}

export default ManagesUserPage;
