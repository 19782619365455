/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import Autocomplete from '../../../utils/Autocomplete';
import AxiosAuth from '../../../utils/AxiosAuth';

function ModalHostingContract({ show, handleClose }) {
    const [inputValueSl, setInputValueSl] = useState('');
    const [domainName, setDomainName] = useState('');
    const [price, setPrice] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [duration, setDuration] = useState(12);
    const [server, setServer] = useState('hostgator');

    const handleInput = (e) => {
        if (e.target.name === 'domainName') {
            setDomainName(e.target.value);
        }

        if (e.target.name === 'price') {
            setPrice(e.target.value);
        }
        if (e.target.name === 'server') {
            setServer(e.target.value);
        }
        if (e.target.name === 'createdDate') {
            setCreatedDate(e.target.value);
        }
        if (e.target.name === 'duration') {
            setDuration(e.target.value);
        }
    };
    const handleContractDomain = () => {
        AxiosAuth.post(ConfigApi.CONTRACT_HOSTING_CREATE, {
            userSl: inputValueSl,
            domainName,
            price,
            createdDate,
            duration,
            server,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create New Hosting Contract:</div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Select User:</label>
                    <div className="contract-create-input-custom">
                        <Autocomplete setInputValueSl={setInputValueSl} />
                    </div>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Domain Name:</label>
                    <input
                        type="text"
                        name="domainName"
                        value={domainName}
                        placeholder="Type Domain Name"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Price (USD):</label>
                    <input
                        type="text"
                        name="price"
                        value={price}
                        placeholder="Type Regular Price"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="schedule">Created Date:</label>
                    <input
                        name="createdDate"
                        value={createdDate}
                        type="datetime-local"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Duration:</label>
                    <select
                        className="form-control"
                        name="duration"
                        value={duration}
                        onChange={handleInput}
                    >
                        <option value={12}>1 Year</option>
                        <option value={24}>2 Year</option>
                        <option value={36}>3 Year</option>
                        <option value={48}>4 Year</option>
                        <option value={60}>5 Year</option>
                        <option value={72}>6 Year</option>
                        <option value={84}>7 Year</option>
                        <option value={96}>8 Year</option>
                        <option value={108}>9 Year</option>
                        <option value={120}>10 Year</option>
                    </select>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Server:</label>
                    <select
                        className="form-control"
                        name="server"
                        value={server}
                        onChange={handleInput}
                    >
                        <option value="hostgator">hostgator</option>
                        <option value="server-3.bikiran.net">server-3.bikiran.net</option>
                        <option value="server6.bikiran.com">server6.bikiran.com</option>
                        <option value="server2.bikiran.com">server2.bikiran.com</option>
                        <option value="server-1.bikiran.net">server-1.bikiran.net</option>
                        <option value="server-2.bikiran.net">server-2.bikiran.net</option>
                        <option value="server-8.bikiran.net">server-8.bikiran.net</option>
                        <option value="server-9.bikiran.net">server-9.bikiran.net</option>
                        <option value="acura.websitewelcome.com">acura.websitewelcome.com</option>
                    </select>
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleContractDomain}
                    >
                        Add
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalHostingContract;
