import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/Logo.svg';

function SidebarSection() {
    const { pathname } = useLocation();

    return (
        <div className="sidebar-menu">
            <div className="sidebar-menu-top">
                <div className="sidebar-menu-logo">
                    {' '}
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
            <div className="sidebar-menu-category">
                <select>
                    <option>BIKIRAN MAIN</option>
                    <option>BIKIRAN SMS</option>
                    <option>BIKIRAN DRIVE</option>
                </select>
            </div>
            <div className="sidebar-menu-list">
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/manage/dashboard/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/dashboard/">
                            <div className="dashboard-icon" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/support/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/support/">
                            <div className="user-roll-icon" />
                            <span>Support Tickets</span>
                        </Link>
                    </li>
                </ul>
                <div className="sidebar-menu-divider">
                    <span>Contracts</span>
                    <div />
                </div>
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/manage/contract/domain/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/contract/domain/">
                            <div className="domain-icon" />
                            <span>Domain</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/contract/hosting/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/contract/hosting/">
                            <div className="hosting-icon" />
                            <span>Hosting</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/contract/server/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/contract/server/">
                            <div className="server-icon" />
                            <span>Server</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/contract/software-subscription/'
                                ? 'sidebar-menu-active'
                                : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/contract/software-subscription/">
                            <div className="reports-icon" />
                            <span>Software Subscription</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/contract/custom-contract/'
                                ? 'sidebar-menu-active'
                                : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/contract/custom-contract/">
                            <div className="reports-icon" />
                            <span>Custom Contract</span>
                        </Link>
                    </li>
                </ul>
                <div className="sidebar-menu-divider">
                    <span>Management</span>
                    <div />
                </div>
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/manage/cart/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/cart/">
                            <div className="cart-icon" />
                            <span>Cart Manage</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/invoice/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/invoice/">
                            <div className="invoice-icon" />
                            <span>Invoice Manage</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/finance/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/finance/">
                            <div className="finance-icon" />
                            <span>Finance & Accounts</span>
                        </Link>
                    </li>

                    <li
                        className={`${
                            pathname === '/manage/user/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/user/">
                            <div className="user-roll-icon" />
                            <span>User</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/logs/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/logs/">
                            <div className="user-roll-icon" />
                            <span>Logs</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/message/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/message/">
                            <div className="campaign-icon" />
                            <span>Messaging</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/reports/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/reports/">
                            <div className="reports-icon" />
                            <span>Reports</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/cpanel-vendor/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/cpanel-vendor/">
                            <div className="reports-icon" />
                            <span>Cpanel Vendor</span>
                        </Link>
                    </li>
                </ul>
                <div className="sidebar-menu-divider">
                    <span>Setting</span>
                    <div />
                </div>
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/manage/service/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/service/">
                            <div className="finance-icon" />
                            <span>Service</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/package/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/package/">
                            <div className="finance-icon" />
                            <span>Packages</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/finance-currencies/'
                                ? 'sidebar-menu-active'
                                : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/finance-currencies/">
                            <div className="finance-icon" />
                            <span>Currencies</span>
                        </Link>
                    </li>
                    <li
                        className={`${
                            pathname === '/manage/finance-gateway/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/finance-gateway/">
                            <div className="finance-icon" />
                            <span>Gateway</span>
                        </Link>
                    </li>
                </ul>
                <div className="sidebar-menu-divider">
                    <span>Website</span>
                    <div />
                </div>
                <ul className="sidebar-menu-list-ul">
                    <li
                        className={`${
                            pathname === '/manage/website/' ? 'sidebar-menu-active' : null
                        } sidebar-menu-list-ul-li`}
                    >
                        <Link to="/manage/website/">
                            <div className="finance-icon" />
                            <span>Client</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="sidebar-footer">
                <button className="sideFooter-user" type="button" id="sideFooterBtn">
                    <div
                        className="sideFooter-user-circle cursor-pointer"
                        style={{ background: '#dfjddd' }}
                    >
                        S
                    </div>
                    <p>Sourav Mallick</p>
                </button>
                <button type="button" className="sideFooterSetting" />
                <button type="button" className="sideFooterLogout" />

                <div className="sidebar-footer-menu display-none">
                    <ul>
                        <li>
                            <Link to="/manage/"> Change Profile</Link>
                        </li>
                        <li>
                            <Link to="/manage/"> Change Password</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SidebarSection;
