/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

function ModalUser({ show, handleClose }) {
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [userName, setUserName] = useState('');

    const handleInput = (e) => {
        if (e.target.name === 'emailAddress') {
            setEmailAddress(e.target.value);
        }
        if (e.target.name === 'phone') {
            setPhone(e.target.value);
        }
        if (e.target.name === 'userName') {
            setUserName(e.target.value);
        }
    };
    const handleContractDomain = () => {
        AxiosAuth.post(ConfigApi.USER_CREATE, {
            emailAddress,
            phone,
            userName,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create New User:</div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Email Address:</label>
                    <input
                        type="email"
                        name="emailAddress"
                        value={emailAddress}
                        placeholder="Type Email Address"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Type:</label>
                    <select>
                        <option value="ind">Ind</option>
                    </select>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Phone Number:</label>
                    <input
                        type="text"
                        name="phone"
                        value={phone}
                        placeholder="Type Phone Number"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Name:</label>
                    <input
                        type="text"
                        name="userName"
                        value={userName}
                        placeholder="Type Full Name"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleContractDomain}
                    >
                        Create
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalUser;
