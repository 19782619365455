import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import HeaderSection from '../../../components/basic/HeaderSection';
import SidebarSection from '../../../components/basic/SidebarSection';

function ManagesHandler() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('Secure-Access')) {
            navigate('/');
        }
    });
    return (
        <div className="content">
            <SidebarSection />
            <div className="main-stricture">
                <HeaderSection />
                <div className="main-panel">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default ManagesHandler;
