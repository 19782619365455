import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ManagesHandler from './app/manages/models/ManagesHandler';
import ManagesCartPage from './app/manages/pages/ManagesCartPage';
import ManagesCpanelVendorPage from './app/manages/pages/ManagesCpanelVendorPage';
import ManagesDashboardPage from './app/manages/pages/ManagesDashboardPage';
import ManagesDomainPage from './app/manages/pages/ManagesDomainPage';
import ManagesFinanceCurrenciesPage from './app/manages/pages/ManagesFinanceCurrenciesPage';
import ManagesFinanceGatewayPage from './app/manages/pages/ManagesFinanceGatewayPage';
import ManagesFinancePage from './app/manages/pages/ManagesFinancePage';
import ManagesHostingPage from './app/manages/pages/ManagesHostingPage';
import ManagesInvoicePage from './app/manages/pages/ManagesInvoicePage';
import ManagesLogsPage from './app/manages/pages/ManagesLogsPage';
import ManagesMessagePage from './app/manages/pages/ManagesMessagePage';
import ManagesReportPage from './app/manages/pages/ManagesReportPage';
import ManagesServerPage from './app/manages/pages/ManagesServerPage';
import ManagesSoftwarePage from './app/manages/pages/ManagesSoftwarePage';
import ManagesSupportPage from './app/manages/pages/ManagesSupportPage';
import ManagesUserPage from './app/manages/pages/ManagesUserPage';
import LoginPage from './app/system/pages/LoginPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import InitInfo from './utils/InitInfo';

export default function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData?.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    if (!show) {
        return null;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/manage/*" element={<ManagesHandler />}>
                    <Route path="dashboard/" element={<ManagesDashboardPage />} />
                    <Route path="support/" element={<ManagesSupportPage />} />
                    <Route path="contract/domain/" element={<ManagesDomainPage />} />
                    <Route path="contract/hosting/" element={<ManagesHostingPage />} />
                    <Route path="contract/server/" element={<ManagesServerPage />} />
                    <Route
                        path="contract/software-subscription/"
                        element={<ManagesSoftwarePage />}
                    />
                    <Route path="cart/" element={<ManagesCartPage />} />
                    <Route path="invoice/" element={<ManagesInvoicePage />} />
                    <Route path="finance/" element={<ManagesFinancePage />} />
                    <Route path="finance-currencies/" element={<ManagesFinanceCurrenciesPage />} />
                    <Route path="finance-gateway/" element={<ManagesFinanceGatewayPage />} />
                    <Route path="user/" element={<ManagesUserPage />} />
                    <Route path="logs/" element={<ManagesLogsPage />} />
                    <Route path="message/" element={<ManagesMessagePage />} />
                    <Route path="Reports/" element={<ManagesReportPage />} />
                    <Route path="cpanel-vendor/" element={<ManagesCpanelVendorPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
