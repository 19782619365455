import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

function ManagesFinanceGatewayPage() {
    const [gatewayAr, setGatewayAr] = useState([]);
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.FINANCE_GATEWAY_LIST}`).then((response) => {
            setGatewayAr(response.data.dataAr);
        });
    }, []);

    return (
        <>
            <div className="main-panel-header">
                <form method="get" action="" className="main-panel-header-search">
                    <div className="sidebar-panel-search">
                        <input
                            type="text"
                            name="content"
                            id="content"
                            placeholder="Search Currency"
                            aria-label=""
                        />
                        <img src={Searchicon} alt="Search" />
                    </div>

                    <button type="submit">Search</button>
                </form>
            </div>
            <div className="main-panel-body">
                <table className="main-panel-body-table mb-3">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Provider</th>
                            <th>title</th>
                            <th>Currency Supported</th>
                            <th>Currency Paymnet</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gatewayAr?.map((row) => (
                            <tr key={row?.sl}>
                                <td>{row?.sl}</td>
                                <td>{row?.provider}</td>
                                <td>{row?.title}</td>
                                <td>{row?.currency_supported}</td>

                                <td>{row?.currency_payment}</td>
                                <td>{row?.status}</td>
                                <td />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ManagesFinanceGatewayPage;
