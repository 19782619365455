const api = process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${api}/api/init`,
    // Auth
    LOGIN: `${api}/login`,
    REGISTER: `${api}/register`,
    LOGOUT: `${api}/logout`,

    // Contract Domain
    CONTRACT_DOMAIN_LIST: `${api}/manages/contract/domain`,
    CONTRACT_DOMAIN_USER_SEARCH: `${api}/manages/contract/domain/user/search`,
    CONTRACT_DOMAIN_USER_SET: `${api}/manages/contract/domain/user/set`,
    CONTRACT_DOMAIN_CREATE: `${api}/manages/contract/domain/create`,

    // Contract Hosting
    CONTRACT_HOSTING_LIST: `${api}/manages/contract/hosting`,
    CONTRACT_HOSTING_CREATE: `${api}/manages/contract/hosting/create`,
    // Contract Server
    CONTRACT_SERVER_LIST: `${api}/manages/contract/server`,
    CONTRACT_SERVER_CREATE: `${api}/manages/contract/server/create`,

    // Contract Cpanel Vendor
    CPANEL_VENDOR_LIST: `${api}/manages/contract/cpanel-vendor`,
    CPANEL_VENDOR_CREATE: `${api}/manages/contract/cpanel-vendor/create`,

    // Users
    USER_LIST: `${api}/manages/users/list`,
    USER_CREATE: `${api}/manages/users/create`,

    // Finance
    FINANCE_CURRENCIES_LIST: `${api}/manages/finance/currencies`,
    FINANCE_CURRENCY_UPDATE: `${api}/manages/finance/currency/update`,
    FINANCE_GATEWAY_LIST: `${api}/manages/finance/gateway`,
};

export default ConfigApi;
